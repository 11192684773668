import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39f2e308 = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _2edfd709 = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _5a0d6312 = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _3c55f0dd = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _2f0cd40d = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _96e5aac4 = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _2e057c0b = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _0ad0a9d8 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _711e93ae = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _3fb27f0e = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _7993bdbd = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _70e1d542 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _d81a3bee = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _ee214288 = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _e10b4b02 = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _20cf666e = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _73777e6e = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _54ff97f2 = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _3934ed71 = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _0272123c = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _de2189f2 = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _b59bb000 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _39f2e308,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _2edfd709,
    name: "form"
  }, {
    path: "/header",
    component: _5a0d6312,
    name: "header"
  }, {
    path: "/healthcheck",
    component: _3c55f0dd,
    name: "healthcheck"
  }, {
    path: "/map",
    component: _2f0cd40d,
    name: "map"
  }, {
    path: "/modal",
    component: _96e5aac4,
    name: "modal"
  }, {
    path: "/news-post",
    component: _2e057c0b,
    name: "news-post"
  }, {
    path: "/page",
    component: _0ad0a9d8,
    name: "page"
  }, {
    path: "/page-template",
    component: _711e93ae,
    name: "page-template"
  }, {
    path: "/preview",
    component: _3fb27f0e,
    name: "preview"
  }, {
    path: "/scene",
    component: _7993bdbd,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _70e1d542,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _d81a3bee,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _ee214288,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _e10b4b02,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _20cf666e,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _73777e6e,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _54ff97f2,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _3934ed71,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _0272123c,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _de2189f2,
    name: "contents-id"
  }, {
    path: "/",
    component: _b59bb000,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
